var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }, [_vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center"
  }, [_c('h1', {
    staticClass: "error-title text-stroke text-transparent"
  }, [_vm._v(" 404 ")]), _c('p', {
    staticClass: "display-4 font-weight-boldest text-white mb-12"
  }, [_vm._v(" How did you get here ")]), _c('p', {
    staticClass: "font-size-h1 font-weight-boldest text-dark-75"
  }, [_vm._v(" Sorry we can't seem to find the page you're looking for. ")]), _c('p', {
    staticClass: "font-size-h4 line-height-md"
  }, [_vm._v(" There may be a misspelling in the URL entered, "), _c('br'), _vm._v("or the page you are looking for may no longer exist. ")])]);

}]

export { render, staticRenderFns }